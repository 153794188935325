@import 'light.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  :root {
    --spacing-none: 0;
    --spacing-xs: 0.125rem;
    --spacing-sm: 0.375rem;
    --spacing-md: 0.625rem;
    --spacing-lg: 0.75rem;
    --spacing-xl: 1rem;
    --spacing-2xl: 1.5rem;
    --spacing-3xl: 1.875rem;
    --spacing-4xl: 2.375rem;
    --spacing-5xl: 3.125rem;
    --spacing-6xl: 3.875rem;
    --spacing-7xl: 4.875rem;
    --spacing-8xl: 6.125rem;
    --spacing-9xl: 7.875rem;

    --label-height: 1.25rem;
    --placeholder-height: 1.25rem;
    --input-height: 2.5rem;
    --check-height: 1.25rem;

    --label-top: calc(-1 * var(--label-height));

    /* Text sizes */
    --text-xs: 0.75rem;
    --text-sm: 0.875rem;
    --text-base: 1rem;
    --text-lg: 1.125rem;
    --text-xl: 1.25rem;
    --text-2xl: 1.5rem;
    --text-3xl: 1.875rem;
    --text-4xl: 2.25rem;
    --text-5xl: 3rem;
    --text-6xl: 3.75rem;
    --text-7xl: 4.5rem;
    --text-8xl: 6rem;
    --text-9xl: 8rem;

    /* Line heights */
    --line-height-xs: 1rem;
    --line-height-sm: 1.25rem;
    --line-height-base: 1.5rem;
    --line-height-lg: 1.75rem;
    --line-height-xl: 1.75rem;
    --line-height-2xl: 2rem;
    --line-height-3xl: 2.25rem;
    --line-height-4xl: 2.5rem;
    --line-height-5xl: 3rem;
    --line-height-6xl: 3.75rem;
    --line-height-7xl: 4.5rem;
    --line-height-8xl: 6rem;
    --line-height-9xl: 8rem;

    /* Heights */
    --height-xs: 2rem; /* 32px */
    --height-sm: 2.25rem; /* 36px */
    --height-md: 2.5rem; /* 40px */
    --height-lg: 2.75rem; /* 44px */
    --height-xl: 3rem; /* 48px */

    /* Shadows */
    /* --shadow-sm: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    --shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
      0px 1px 3px 0px rgba(0, 0, 0, 0.1); */
    /* --shadow-md: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
      0px 4px 6px -1px rgba(0, 0, 0, 0.1); */
    /* --shadow-lg: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
      0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    --shadow-xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04),
      0px 20px 25px -5px rgba(0, 0, 0, 0.1);
    --shadow-2xl: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
    --shadow-inner: 0px 2px 4px 0px rgba(0, 0, 0, 0.06) inset; */

    --shadow-page-layout-top: 0px 4px 6px -1px rgba(55, 88, 249, 0.1),
      0px 2px 4px -1px rgba(55, 88, 249, 0.06);

    --shadow-page-layout-left: 0px 10px 15px -3px rgba(55, 88, 249, 0.1),
      0px 4px 6px -2px rgba(55, 88, 249, 0.05);

    --shadow-section: 0px 10px 15px -3px rgba(55, 88, 249, 0.1),
      0px 4px 6px -2px rgba(55, 88, 249, 0.05);

    /* Border radii */
    --rounded-none: 0;
    --rounded-sm: 0.125rem;
    --rounded: 0.25rem;
    --rounded-md: 0.375rem;
    --rounded-lg: 0.5rem;
    --rounded-xl: 0.75rem;
    --rounded-2xl: 1rem;
    --rounded-3xl: 1.5rem;
    --rounded-full: 50%;

    /* Scrollbar width */
    --scrollbar-width: 8px;

    /* Opacities */
    --permission-opacity: 0.65;

    /* input */
    :focus-visible {
      @apply outline-none;
    }

    /* width */
    ::-webkit-scrollbar {
      @apply w-[var(--scrollbar-width)];
    }

    /* Track */
    ::-webkit-scrollbar-track {
      @apply rounded-xl w-[var(--scrollbar-width)];
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      @apply bg-gray-600 rounded-xl;
    }

    .rotate-right {
      @apply transform rotate-90;
    }
  }

  @-moz-document url-prefix() {
    * {
      scrollbar-width: thin !important;
    }
  }

  body {
    @apply h-screen bg-gray-50;
    font-family: sans-serif;
  }
}

@layer components {
  /*#region Common */

  [uipagelayout] {
    display: grid;
    height: 100vh;
    overflow: hidden;

    --handle-height: 4px;
    --handle-width: 4px;
    --handle-color: transparent;

    &.blank {
      grid-template-areas: 'center';
      grid-template-columns: auto;
      grid-template-rows: auto;
    }
    &.center {
      grid-template-areas: 'center';
      grid-template-columns: auto;
      grid-template-rows: auto;
      > .center {
        display: grid;
        place-items: stretch;
        grid-auto-rows: 0px 1fr;
      }
    }
    &.top {
      grid-template-areas:
        'top'
        'topHandle'
        'center';
      grid-template-columns: auto;
      grid-template-rows: var(--top-area-height) var(--handle-height) auto;
    }
    &.bottom {
      grid-template-areas:
        'center'
        'bottomHandle'
        'bottom';
      grid-template-columns: auto;
      grid-template-rows: auto var(--handle-height) var(--bottom-area-height);
    }
    &.top-bottom {
      grid-template-areas:
        'top'
        'topHandle'
        'center'
        'bottomHandle'
        'bottom';
      grid-template-columns: auto;
      grid-template-rows:
        var(--top-area-height) var(--handle-height) auto var(--handle-height)
        var(--bottom-area-height);
    }
    &.left {
      grid-template-areas: 'left leftHandle center';
      grid-template-columns: var(--left-area-width) var(--handle-width) auto;
      grid-template-rows: auto;
    }
    &.right {
      grid-template-areas: 'center rightHandle right';
      grid-template-columns: auto var(--handle-width) var(--right-area-width);
      grid-template-rows: auto;
    }
    &.left-right {
      grid-template-areas: 'left leftHandle center rightHandle right';
      grid-template-columns:
        var(--left-area-width) var(--handle-width) auto var(--handle-width)
        var(--right-area-width);
      grid-template-rows: auto;
    }
    &.top-left {
      grid-template-areas:
        'top top top'
        'left topHandle topHandle'
        'left leftHandle center';
      grid-template-columns: var(--left-area-width) var(--handle-width) auto;
      grid-template-rows: var(--top-area-height) var(--handle-height) auto;
    }
    &.top-right {
      grid-template-areas:
        'top top top'
        'topHandle rightHandle right'
        'center rightHandle right';
      grid-template-columns: auto var(--handle-width) var(--right-area-width);
      grid-template-rows: var(--top-area-height) var(--handle-height) auto;
    }
    &.top-left-right {
      grid-template-areas:
        'top top top top top'
        'left leftHandle topHandle rightHandle right'
        'left leftHandle center rightHandle right';
      grid-template-columns:
        var(--left-area-width) var(--handle-width) var(--center-area-width)
        var(--handle-width) var(--right-area-width);
      grid-template-rows: var(--top-area-height) var(--handle-height) auto;
    }
    &.bottom-left {
      grid-template-areas:
        'left leftHandle center'
        'left leftHandle bottomHandle'
        'left leftHandle bottom';
      grid-template-columns: var(--left-area-width) var(--handle-width) auto;
      grid-template-rows: auto var(--handle-height) var(--bottom-area-height);
    }
    &.bottom-right {
      grid-template-areas:
        'center rightHandle right'
        'bottomHandle rightHandle right'
        'bottom rightHandle right';
      grid-template-columns: auto var(--handle-width) var(--right-area-width);
      grid-template-rows: auto var(--handle-height) var(--bottom-area-height);
    }
    &.bottom-left-right {
      grid-template-areas:
        'left leftHandle center rightHandle right'
        'left leftHandle bottomHandle rightHandle right'
        'left leftHandle bottom rightHandle right';
      grid-template-columns:
        var(--left-area-width) var(--handle-width) auto var(--handle-width)
        var(--right-area-width);
      grid-template-rows: auto var(--handle-height) var(--bottom-area-height);
    }
    &.all {
      grid-template-areas:
        'top top top top top'
        'left leftHandle topHandle rightHandle right'
        'left leftHandle center rightHandle right'
        'left leftHandle bottomHandle rightHandle right'
        'left leftHandle bottom rightHandle right';
      grid-template-columns:
        var(--left-area-width) var(--handle-width) auto var(--handle-width)
        var(--right-area-width);
      grid-template-rows:
        var(--top-area-height) var(--handle-height) auto var(--handle-height)
        var(--bottom-area-height);
    }

    > div {
      @apply bg-gray-50 relative z-[1];
    }
    div.top {
      box-shadow: var(--shadow-page-layout-top);
    }
    div.left {
      box-shadow: var(--shadow-page-layout-left);
    }
    div.center {
      @apply h-auto;
    }

    .top {
      grid-area: top;
      place-self: stretch;
      height: var(--top-area-height);
      width: var(--top-area-width);
    }

    .left {
      grid-area: left;
      place-self: stretch;
      height: var(--left-area-height);
      width: var(--left-area-width);
    }

    .center {
      grid-area: center;
      place-self: stretch;
      height: var(--center-area-height);
      width: var(--center-area-width);
      /* > [uipage] {
      height: calc(100vh - var(--top-area-height));
    } */
    }

    .right {
      grid-area: right;
      place-self: stretch;
      height: var(--right-area-height);
      width: var(--right-area-width);
    }

    .bottom {
      grid-area: bottom;
      place-self: stretch;
      height: var(--bottom-area-height);
      width: var(--bottom-area-width);
    }

    .topHandle {
      grid-area: topHandle;
      height: var(--handle-height);

      &:hover {
        border-top: 1px dashed black;
      }
    }

    .bottomHandle {
      grid-area: bottomHandle;
      height: var(--handle-height);

      &:hover {
        border-bottom: 1px dashed black;
      }
    }

    .leftHandle {
      grid-area: leftHandle;
      width: var(--handle-width);

      &:hover {
        border-left: 1px dashed black;
      }
    }

    .rightHandle {
      grid-area: rightHandle;
      width: var(--handle-width);

      &:hover {
        border-right: 1px dashed black;
      }
    }

    .leftHandle,
    .rightHandle {
      cursor: col-resize;
    }

    .topHandle,
    .bottomHandle {
      cursor: row-resize;
    }

    .leftHandle,
    .rightHandle,
    .topHandle,
    .bottomHandle {
      background-color: var(--handle-color);
    }
  }

  [uipagetoolbar] {
    @apply grid h-full items-center bg-white z-10;
    grid-template-columns: 80px 1px 1fr auto;
    grid-template-areas: 'logo separator app-tools common-tools';

    [area='logo'] {
      @apply flex place-self-center p-0.5 h-6 w-6 rounded-full;
      background-color: rgba(42, 64, 89, 1);
    }

    [area='separator'] {
      @apply bg-blue-100 h-[20px] w-[1px];
    }

    [area='app-tools'] {
      @apply px-6;
    }

    [area='common-tools'] {
      @apply flex place-items-center text-[var(--color-blue-gray-400)] mr-6;
      > icon + icon {
        @apply ml-8;
      }
      > icon + span {
        @apply ml-2;
      }
      > span {
        @apply text-gray-700 text-sm font-medium;
      }
    }
  }

  [uipagenav] {
    @apply flex flex-col pt-3 bg-white h-full;
    > [uipagenavheader] {
      > [uipagenavitem] {
        @apply block leading-[40px] h-[40px] px-4 border-b-[1px] border-blue-100 text-gray-500;
      }
    }

    > [uipagenavcontent] {
      > [uipagenavitem] {
        @apply block leading-[40px] overflow-hidden whitespace-nowrap px-4 h-[40px] hover:bg-indigo-50 text-sm font-normal text-[#707070] cursor-pointer;
      }
    }
  }

  [uipage] {
    @apply flex flex-col h-[calc(100vh-var(--top-area-height)-1rem)] gap-y-3 bg-gray-50;

    [uipageheader] {
      @apply grid items-center gap-4;
      &:empty {
        @apply hidden;
      }
    }

    [uipagecontent] {
      @apply grid gap-y-3 overflow-y-auto bg-gray-50;

      > * {
        @apply mx-10;
      }

      &[uipermission='View'] {
        section:not([uipermission='Write']) {
          [uisectioncontent] {
            [uicontrol]:not(section-group):not([uipermission='Write']) {
              @apply !pointer-events-none opacity-[var(--permission-opacity)];
              * {
                @apply !pointer-events-none opacity-[var(--permission-opacity)];
              }
            }
            [section-group]:not([uipermission='Write']) {
              [uisectiongroupcontent] {
                [uicontrol]:not([uipermission='Write']) {
                  @apply !pointer-events-none opacity-[var(--permission-opacity)];
                  * {
                    @apply !pointer-events-none opacity-[var(--permission-opacity)];
                  }
                }
              }
            }
          }
        }
      }
    }

    &[uipermission='View'] {
      [uipagecontent] {
        section:not([uipermission='Write']) {
          [uisectioncontent] {
            [uicontrol]:not(section-group):not([uipermission='Write']) {
              @apply !pointer-events-none opacity-[var(--permission-opacity)];
              * {
                @apply !pointer-events-none opacity-[var(--permission-opacity)];
              }
            }
            [section-group]:not([uipermission='Write']) {
              [uisectiongroupcontent] {
                [uicontrol]:not([uipermission='Write']) {
                  @apply !pointer-events-none opacity-[var(--permission-opacity)];
                  * {
                    @apply !pointer-events-none opacity-[var(--permission-opacity)];
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  [uicontrol] {
    @apply relative;

    &.no-border {
      > [uivalue] {
        @apply border-white;
      }
    }

    &[uihidden] {
      @apply hidden;
    }

    &[uidisabled] {
      @apply pointer-events-none;

      [uivalue] {
        @apply bg-[var(--color-primary-disabled)] text-gray-400 border-[var(--color-primary-disabled)] pointer-events-none;
      }
      [uitoolstrip] {
        @apply !text-gray-400 bg-[var(--color-primary-disabled)];
        > *:not([uidefaulttool]) {
          @apply hidden;
        }
        > [uidefaulttool] {
          @apply pointer-events-none;
        }
      }
    }
    btn[uidisabled] {
      @apply bg-[var(--color-primary-disabled)] text-gray-400 border-[var(--color-primary-disabled)] pointer-events-none;
    }

    &:not([uipage]):not(section):not(section-group) {
      &[uipermission='View'] {
        @apply !pointer-events-none opacity-[var(--permission-opacity)];
        * {
          @apply !pointer-events-none;
        }
      }
    }
  }

  [uivalue] {
    @apply flex items-center leading-[var(--line-height-sm)] text-sm text-gray-600 border-white rounded-md w-full h-[var(--input-height)] min-h-[var(--input-height)] border-x-[16px] border-y-[8px];

    &::placeholder,
    &::-webkit-input-placeholder {
      @apply text-gray-400 !leading-[var(--line-height-sm)];
    }

    &[readonly] {
      @apply cursor-pointer;
    }
  }

  [uitoolstrip] {
    @apply flex items-center place-content-end gap-sm bg-white;

    * {
      @apply cursor-pointer;
    }
  }

  [uiinput] {
    @apply flex items-center bg-white;

    &:not(check, file-upload) {
      @apply border-[1px] border-solid border-gray-200 rounded-md;
    }

    &[uifocused],
    &:hover {
      @apply border-gray-700;

      &[uistate='error'] {
        @apply border-[var(--border-error)];
      }
      &[uistate='warning'] {
        @apply border-[var(--border-warning)];
      }
      &[uistate='dirty'] {
        @apply border-[var(--color-primary)];
      }

      [uitoolstrip] {
        [uidefaulttool] {
          @apply text-gray-800;
        }
      }
    }

    label {
      @apply absolute top-[calc(-1*var(--label-height)-2px)] flex items-center gap-md text-sm text-gray-700 font-normal h-[var(--label-height)];
    }

    &.label-inline {
      label {
        @apply relative whitespace-nowrap top-[0px] flex items-center leading-[var(--line-height-sm)] text-sm text-gray-600 pl-xl;
      }
    }

    &:has([uitoolstrip] > [uidefaulttool]:hover) {
      @apply border-gray-800;
    }

    [uitoolstrip] {
      @apply absolute top-0 pl-2 text-xs text-gray-500 h-[var(--input-height)] right-[var(--spacing-xl)] pointer-events-none;

      *:not([uidefaulttool]) {
        @apply hover:text-gray-800 pointer-events-auto;
      }

      [uidefaulttool] {
        @apply hover:text-gray-800 pointer-events-none;
      }

      &:empty {
        @apply hidden;
      }
    }

    [uifooter] {
      @apply absolute bottom-[calc(-1*var(--label-height)-2px)] flex items-center gap-md text-xs text-gray-500 font-normal h-[var(--label-height)] whitespace-nowrap;
    }

    &[uistate='error'] {
      @apply hover:border-error;

      [uifooter] {
        @apply text-error;
      }
    }

    &[uistate='warning'] {
      @apply hover:border-warning;

      [uifooter] {
        @apply text-warning;
      }
    }
  }

  [uipopup] {
    @apply absolute top-[calc(var(--input-height)+4px)] z-10 w-full p-md bg-white border-[1px] border-solid border-gray-200 shadow-md;
  }

  [uiinfo] {
    @apply cursor-help;
  }

  [uipermission='None'] {
    @apply hidden;
  }

  [uipermission='Edit'] {
    &:hover {
      &::after {
        content: 'Edit Permissions';
        @apply cursor-pointer text-xl text-blue-950 bg-blue-100 h-full w-full absolute top-0 left-0 flex p-1 opacity-90;
      }
    }
  }

  /*#endregion */

  [class*='asset-class'] {
    @apply p-md border-0 rounded-md flex items-center justify-center place-self-stretch bg-gray-400;
  }

  /*#region Specifics */

  section {
    &:has([uisectioncontent]:empty) {
      @apply hidden;
    }
    @apply border-t-0 border-solid border-transparent rounded-t-md;
    box-shadow: var(--shadow-section);

    [uisectionheader] {
      @apply flex items-center cursor-pointer gap-x-md text-xl text-gray-500 font-medium bg-white p-lg border-t-0 border-b-[1px] border-solid border-blue-100 rounded-t-md;

      &:hover {
        [uitoolstrip] {
          @apply opacity-100;
        }
      }

      [uitoolstrip] {
        @apply bg-inherit opacity-70 border-l-8 border-l-white;
      }
    }

    [uispacer] {
      @apply flex-grow;
    }

    [uisectionheader='collapsed'] {
      @apply border-b-0 rounded-b-md;
    }

    &:not(:has([uisectionheader])) {
      [uisectioncontent] {
        @apply border-t-2 border-solid border-transparent rounded-t-md;
      }
    }

    [uisectioncontent] {
      @apply items-start bg-white pt-3xl pb-3xl pl-lg pr-lg border-b-0 border-solid border-transparent rounded-b-md gap-x-xl gap-y-5xl;

      &:not(.flex) {
        @apply grid;
      }
    }

    &.unstyled {
      @apply shadow-none;

      [uisectioncontent] {
        @apply bg-transparent border-0 py-2 px-0;
      }
    }

    &[uipermission='View'] {
      [uisectioncontent] {
        [uicontrol]:not(section-group):not([uipermission='Write']) {
          @apply !pointer-events-none opacity-[var(--permission-opacity)];
          * {
            @apply !pointer-events-none opacity-85;
          }
        }
        [section-group]:not([uipermission='Write']) {
          [uisectiongroupcontent] {
            [uicontrol]:not([uipermission='Write']) {
              @apply !pointer-events-none opacity-[var(--permission-opacity)];
              * {
                @apply !pointer-events-none opacity-[var(--permission-opacity)];
              }
            }
          }
        }
      }
    }
  }

  section-group {
    [uisectiongroupheader] {
      @apply flex items-center cursor-pointer gap-md pb-[4px] text-lg text-gray-500 pr-md font-semibold;
    }

    [uisectiongroupcontent] {
      @apply border-l-[5px] border-solid border-blue-200 grid items-start bg-white pt-[48px] pb-xl px-[20px] ml-[2.4px] border-b-0 gap-x-xl gap-y-5xl;
    }

    &:has(*) {
      [uisectiongroupempty] {
        @apply hidden;
      }
    }

    &[uipermission='View'] {
      [uisectiongroupcontent] {
        [uicontrol]:not([uipermission='Write']) {
          @apply !pointer-events-none opacity-[var(--permission-opacity)];
          * {
            @apply !pointer-events-none opacity-[var(--permission-opacity)];
          }
        }
      }
    }
  }

  modal {
    @apply !absolute top-0 left-0 w-full h-full flex items-center justify-center bg-none pointer-events-none;

    dialog::backdrop {
      @apply bg-gray-900 opacity-50;
    }

    dialog {
      @apply p-lg h-fit w-full sm:w-fit rounded opacity-100 pointer-events-auto top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%] m-0 z-10;

      [uimodalheader] {
        @apply flex items-center justify-between font-bold;

        [uimodalclosebtn] {
          @apply hover:opacity-70;
        }
      }

      [uimodalcontent] {
        @apply pt-lg pb-lg;
      }

      [uimodalfooter] {
        @apply flex gap-x-sm items-center place-content-end w-full;
      }
    }
  }

  dropdown,
  multi-dropdown {
    [uivalue] {
      @apply flex items-center;
    }

    [uimoreselection] {
      @apply bg-white text-gray-600 p-md;
    }

    &[uidisabled] {
      [uiselection] {
        @apply bg-[var(--color-primary-disabled)] text-gray-500;
        icon {
          ng-icon {
            @apply text-gray-400;
          }
        }
      }
    }

    [uipopup] {
      @apply max-h-[400px] overflow-y-auto;

      text {
        @apply mt-md mb-md sticky top-0 bg-white outline-[0.625rem] outline-white outline;
      }

      [uipopupsearch] {
        @apply w-full;
      }

      [uipopupoption] {
        @apply flex items-center p-md hover:bg-gray-200 hover:text-primary rounded-md  gap-sm cursor-pointer;
      }
    }
  }

  multi-dropdown {
    [uivalue] {
      @apply h-auto gap-sm overflow-hidden;
    }
    [uiplaceholder] {
      @apply leading-[var(--line-height-sm)];
    }
    [uipopupmore] {
      @apply flex flex-wrap gap-sm;
    }
    chip {
      @apply bg-blue-100 text-gray-600 text-sm font-normal rounded-md px-3;

      > span {
        @apply overflow-hidden whitespace-nowrap max-w-[calc(100%-14px)];
      }

      &.sm {
        @apply h-[24px];
      }

      &.md {
        @apply h-[32px];
      }

      &:has(icon[prefix]) {
        @apply pl-2;
      }

      &:has(icon[suffix]) {
        @apply pr-2;
      }
    }
  }

  date {
    [uipopup] {
      @apply right-0 w-[300px] grid grid-cols-7 place-items-stretch;

      [uipopupoption] {
        @apply flex items-center justify-center p-md hover:bg-gray-200 hover:text-primary rounded-md gap-sm cursor-pointer;
      }

      [uipopupoptioncenter] {
        @apply col-span-5;
      }

      [uipopupoptiondisabled='true'] {
        @apply hover:bg-white cursor-default !text-gray-400;
      }
    }
  }

  date,
  dropdown {
    [uipopup] {
      [uipopupoptionselected='true'] {
        @apply bg-primary text-white;
      }
    }
  }

  check[uiinput] {
    @apply gap-md h-[var(--input-height)];

    label {
      @apply relative top-auto left-auto cursor-pointer;
    }

    [uitoolstrip] {
      @apply flex items-center place-content-end gap-sm right-[calc(-2*(var(--spacing-md)))];
    }
  }

  btn {
    @apply flex items-center justify-center rounded-md w-min whitespace-nowrap text-base border-[1px] cursor-pointer font-medium;

    &[uitool] {
      @apply !p-0 border-none;
    }

    &.xs {
      @apply h-[--height-xs];
    }

    &.sm {
      @apply h-[--height-sm];
    }

    &.md {
      @apply h-[--height-md];
    }

    &.lg {
      @apply h-[--height-lg];
    }

    &.xl {
      @apply h-[--height-xl];
    }

    &:not(.iconOnly) {
      @apply pl-2xl pr-2xl;
    }

    &.iconOnly {
      @apply p-sm;
    }

    &.primary {
      @apply text-white bg-[var(--color-primary)] border-[var(--color-primary)] hover:bg-[var(--color-primary-hover)] active:bg-[var(--color-primary-focus)];

      &[uidisabled] {
        @apply text-[var(--color-primary-disabled-alt)] bg-[var(--color-primary-disabled)] border-[var(--color-primary-disabled)];
      }

      &[uistate='error'] {
        @apply bg-[var(--color-error)] border-[var(--color-error)] hover:bg-[var(--color-error)] active:bg-[var(--color-error)];
      }

      &[uistate='warning'] {
        @apply bg-[var(--color-warning)] border-[var(--color-warning)] hover:bg-[var(--color-warning)] active:bg-[var(--color-warning)];
      }
    }

    &.secondary {
      @apply text-primary bg-white border-[var(--color-primary)] hover:bg-[var(--color-secondary-hover)] active:bg-[var(--color-secondary-focus)];

      &[uidisabled] {
        @apply text-[var(--color-primary-disabled-alt)] bg-[var(--color-primary-disabled)] border-[var(--color-primary-disabled-alt)];
      }

      &[uistate='error'] {
        @apply text-[var(--color-error)] border-[var(--color-error)];
      }

      &[uistate='warning'] {
        @apply text-[var(--color-warning)] border-[var(--color-warning)];
      }
    }

    &.tertiary {
      @apply text-primary bg-[var(--color-white)] border-[var(--color-white)] hover:bg-[var(--color-secondary-hover)] hover:border-[var(--color-secondary-hover)] active:bg-[var(--color-secondary-focus)] active:border-[var(--color-secondary-focus)];

      &[uistate='error'] {
        @apply text-[var(--color-error)];
      }

      &[uistate='warning'] {
        @apply text-[var(--color-warning)];
      }
    }

    button {
      @apply flex items-center justify-center gap-x-3;
    }
  }

  chip {
    @apply flex items-center justify-center gap-x-1;
  }

  input[type='checkbox'] {
    @apply gap-sm cursor-pointer w-[var(--check-height)] min-w-[var(--check-height)] h-[var(--check-height)] min-h-[var(--check-height)] rounded-md accent-primary;
  }

  textarea[uivalue] {
    @apply py-0 resize-none;
    overflow: hidden;
    transition: overflow 0.5s ease;
    height: var(--line-height-base);
    line-height: var(--line-height-base);
  }

  textarea[uivalue].scrollable {
    overflow: auto;
  }

  textarea[uivalue]::placeholder {
    vertical-align: middle;
  }

  spreadsheet {
    @apply flex flex-col;

    [uispreadsheet] {
      @apply w-full;

      table-layout: fixed;

      [uispreadsheetheader] {
        @apply cursor-pointer;

        &.marked {
          @apply bg-gray-200;
        }
      }

      [uispreadsheetsummary] {
        @apply cursor-default;
      }

      [uispreadsheetcell] {
        @apply relative;

        --cell-state-color: var(--color-primary);

        &[uistate] {
          &:not([uistate='pristine']) {
            &::before {
              position: absolute;
              top: 0px;
              left: 0px;
              content: '';
              width: 0;
              height: 0;
              border-top: 0px solid transparent;
              border-bottom: 7px solid transparent;
              border-left: 7px solid var(--cell-state-color);
            }
          }
        }
        &[uistate='error'] {
          --cell-state-color: var(--color-error);
        }
        &[uistate='warning'] {
          --cell-state-color: var(--color-warning);
        }
      }

      [uispreadsheetcellinput] {
        @apply w-full h-full border-0 rounded-none;
      }

      th {
        @apply p-1 border-t-[1px] select-none;
      }

      td {
        @apply select-none !outline-offset-[-1px];
      }

      th,
      td:not([uispreadsheetcell]):not([uispreadsheetsummary]) {
        @apply text-gray-500 font-normal;
      }

      td,
      th {
        @apply text-sm overflow-hidden border-r-[1px] border-solid border-blue-100;

        &[uispreadsheetheader],
        &[uispreadsheetsummary] {
          @apply border-l-[1px] text-center bg-gray-100;
        }
      }

      td:not(:first-child):not([uispreadsheetsummary]) {
        @apply cursor-cell;

        &.deleted {
          @apply bg-black text-white after:content-["Deleted"] text-center;
        }
        &.selected {
          @apply outline outline-[3px] outline-[var(--color-primary)] z-[1];
        }
        &.marked {
          @apply bg-blue-100 opacity-100;
        }
      }

      tr {
        td,
        th {
          @apply border-b-[1px] border-solid border-gray-200;
        }
      }
    }
  }

  /*#endregion */
}
