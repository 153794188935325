@tailwind components;

@layer components {
  page-home {
    @apply w-[50vw] h-[50vh];
  }
  [uipageheader] {
    @apply pl-10 pr-12 bg-white;
    box-shadow: var(--shadow-page-layout-top);

    @apply pt-2xl pb-xl;
    grid-template-rows: 50px;
    grid-template-columns: 50px auto auto;
    grid-template-areas: 'iconArea titleArea controlsArea';

    [IconArea] {
      grid-area: iconArea;
      @apply shadow-lg place-self-stretch;
    }

    [TitleArea] {
      grid-area: titleArea;
      grid-template-columns: auto;
      grid-template-rows: 30px 20px;
      grid-template-areas:
        'titleText'
        'subtitle';

      @apply grid gap-0 overflow-hidden place-self-stretch;

      text-overflow: ellipsis;
      white-space: nowrap;

      [TitleText] {
        grid-area: titleText;
        @apply content-center text-xl font-medium text-gray-500 place-self-stretch;
      }

      [Subtitle] {
        grid-area: subtitle;

        @apply text-xs font-normal text-gray-500;

        align-self: start;
        display: flex;
        gap: 8px;

        > div {
          @apply flex gap-1 items-center;
        }

        icon,
        span {
          color: var(--aksia-dark-grey);
        }
      }
    }

    [ControlsArea] {
      grid-area: controlsArea;
      @apply flex flex-row-reverse gap-2 justify-self-end;
    }
  }

  [login-toolbar] {
    @apply grid h-full items-center gap-2 bg-white z-10;
    grid-template-columns: 40px 1px auto;
    grid-template-areas: 'logo separator title';

    [area='logo'] {
      @apply flex flex-col gap-2 place-self-center content-center p-0.5 h-8 w-8 rounded-full;
      background-color: rgba(42, 64, 89, 1);
    }

    [area='version'] {
      @apply text-xs text-gray-500 place-self-center;
    }

    [area='separator'] {
      @apply bg-blue-100 h-[20px] w-[1px];
    }

    [area='title'] {
      @apply text-2xl font-medium text-gray-500 place-self-start;
    }
  }

  * {
    &.isLoading {
      @apply before:bg-white before:w-full before:h-full before:fixed before:z-10;
      @apply after:text-blue-900 after:z-20 after:w-full after:h-full after:fixed after:flex after:items-center after:justify-center after:content-['Loading...'];
    }
  }
}
