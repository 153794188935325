html[data-theme='light'] {
  --color-primary: rgba(65, 91, 217, 1);
  --color-primary-hover: rgba(0, 58, 255, 1);
  --color-primary-disabled: rgba(249, 250, 251, 1);
  --color-primary-disabled-alt: rgba(203, 213, 225, 1);
  --color-primary-focus: rgba(0, 58, 255, 1);
  --color-secondary: rgba(255 255 255, 1);
  --color-secondary-hover: rgba(225, 232, 255, 1);
  --color-secondary-disabled: rgba(255 255 255, 1);
  --color-secondary-focus: rgba(205, 216, 254, 1);
  --color-aksia: #1b6d85;
  --color-success: rgba(5, 150, 105, 1);
  --color-error: rgba(220, 38, 38, 1);
  --color-warning: rgba(251, 191, 36, 1);
  --color-info: rgb(59 130 246);
  --color-white: rgb(255 255 255);
  --color-black: rgb(0 0 0);
  --color-blue-gray-400: rgba(148, 163, 184, 1);
}
